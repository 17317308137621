import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listTattooDetails, updateTattoo } from '../actions/tattooActions'
import { TATTOO_UPDATE_RESET } from '../constants/tattooConstants'

const TattooEditScreen = () => {
  const { id } = useParams()
  const tattooId = id

  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [image, setImage] = useState('')
  const [exclusive, setExclusive] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const tattooDetails = useSelector((state) => state.tattooDetails)
  const { loading, error, tattoo } = tattooDetails

  const tattooUpdate = useSelector((state) => state.tattooUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = tattooUpdate

  const history = useNavigate()

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: TATTOO_UPDATE_RESET })
      history('/admin/panel/tattoos')
    } else {
      if (!tattoo.name || tattoo._id !== tattooId) {
        dispatch(listTattooDetails(tattooId))
      } else {
        setName(tattoo.name)
        setPrice(tattoo.price)
        setImage(tattoo.image)
        setExclusive(tattoo.exclusive)
        setQuantity(tattoo.quantity)
        setDescription(tattoo.description)
      }
    }
  }, [dispatch, history, tattooId, tattoo, successUpdate])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]

    const formData = new FormData()

    formData.append('image', file)

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateTattoo({
        _id: tattooId,
        name,
        price,
        image,
        exclusive,
        description,
        quantity,
      })
    )
  }

  return (
    <>
      <Link to='/admin/panel/tattoos' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit Tattoo Design</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Tattoo Design Name</Form.Label>

              <Form.Control
                type='name'
                placeholder='Enter tattoo design name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='price'>
              <Form.Label className='margin-top-1point5rem'>Price</Form.Label>

              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(parseInt(e.target.value))}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label className='margin-top-1point5rem'>Image</Form.Label>

              {/* <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control> */}

              <Form.Control
                type='file'
                label='Choose File'
                onChange={uploadFileHandler}
              ></Form.Control>

              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='exclusive'>
              <Form.Label className='margin-top-1point5rem'>
                Exclusive?
              </Form.Label>

              <Form.Select
                value={exclusive}
                onChange={(e) => setExclusive(e.target.value)}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId='quantity'>
              <Form.Label className='margin-top-1point5rem'>
                How many clients can currently get this tattoo?
              </Form.Label>

              <Form.Control
                type='number'
                placeholder='Enter quantity'
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label className='margin-top-1point5rem'>
                Tattoo Design Description
              </Form.Label>

              <Form.Control
                as='textarea'
                rows={5}
                type='text'
                placeholder='Enter item description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default TattooEditScreen
