import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { listFaqs } from '../actions/faqActions'
import { Card, Accordion, Row, Col } from 'react-bootstrap'
import FadeIn from 'react-fade-in'

const FaqScreen = ({ faqs }) => {
  // const dispatch = useDispatch()

  // const faqList = useSelector((state) => state.faqList)

  // const { loading, error, faqs } = faqList

  // useEffect(() => {
  //   dispatch(listFaqs())
  // }, [dispatch])

  return (
    <FadeIn>
      <Meta />

      <h1 className='text-align-center'>FAQ</h1>

      {/* {loading ? (
        <></>
      ) : ( */}
      <Card
        style={{ backgroundColor: '#FFFFFF00', borderColor: '#FFFFFF00' }}
        id='faqCard'
      >
        <Accordion flush>
          <FadeIn>
            {faqs.map((faq, index) => (
              <div key={index}>
                <Accordion.Item
                  eventKey={index}
                  key={faq._id}
                  className='faqAccordionItem'
                >
                  <Accordion.Header>
                    <div
                      style={{ paddingRight: '20px' }}
                      className='white-space-pre-line'
                    >
                      {faq.question}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='white-space-pre-line'>{faq.answer}</div>
                  </Accordion.Body>
                </Accordion.Item>
                <div
                  className='faqItemDivider'
                  key={faq._id + '.divider'}
                ></div>
              </div>
            ))}
          </FadeIn>
        </Accordion>
      </Card>
      {/* )} */}
    </FadeIn>
  )
}

export default FaqScreen
