import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'
import { getAbout } from '../actions/aboutActions'
import { Card } from 'react-bootstrap'
import FadeIn from 'react-fade-in'

const AboutScreen = ({ about }) => {
  // const dispatch = useDispatch()

  // const aboutGet = useSelector((state) => state.aboutGet)
  // const { success, loading, error, about } = aboutGet

  // useEffect(() => {
  //   dispatch(getAbout())
  // }, [dispatch])

  return (
    <>
      <Meta />

      <FadeIn>
        <h1 className='text-align-center'>About Taylor</h1>
        {/* </FadeIn> */}
        {/* {loading ? (
        <></>
      ) : ( */}
        {/* <FadeIn> */}
        <Row>
          <Col sm={5} md={5} lg={4} id='aboutImageCol'>
            <Card id='aboutScreenImageCard'>
              <Card.Img
                src={about.image}
                id='aboutScreenImage'
                loading='lazy'
              />
            </Card>
          </Col>

          <Col sm={7} md={7} lg={8} id='aboutTextCol'>
            <Card id='aboutScreenTextCard'>
              <Card.Body id='aboutScreenTextBody'>
                <Card.Text
                  className='white-space-pre-line'
                  id='aboutScreenText'
                >
                  {about.bio}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </FadeIn>
      {/* )} */}
    </>
  )
}

export default AboutScreen
