import {
  EMAIL_RECEIPT_REQUEST,
  EMAIL_RECEIPT_SUCCESS,
  EMAIL_RECEIPT_FAIL,
  EMAIL_RECEIPT_RESET,
} from '../constants/emailConstants'

export const emailReceiptReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_RECEIPT_REQUEST:
      return {
        loading: true,
      }
    case EMAIL_RECEIPT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      }
    case EMAIL_RECEIPT_FAIL:
      return {
        loading: false,
        error: action.payload,
      }

    case EMAIL_RECEIPT_RESET:
      return {}

    default:
      return state
  }
}
