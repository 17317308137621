import { React, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container, Nav, Navbar, Row } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import ProfileScreen from './screens/ProfileScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import AdminProductScreen from './screens/AdminProductScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import AdminOrderScreen from './screens/AdminOrderScreen'
import StoreScreen from './screens/StoreScreen'
import PortfolioScreen from './screens/PortfolioScreen'
import AboutScreen from './screens/AboutScreen'
import FaqScreen from './screens/FaqScreen'
import SwiperCarousel from './components/SwiperCarouselV2'
import AdminPanelScreen from './screens/AdminPanelScreen'
import AdminPortfolioScreen from './screens/AdminPortfolioScreen'
import AdminAboutScreen from './screens/AdminAboutScreen'
import AdminLogoScreen from './screens/AdminLogoScreen'
import AdminTattooScreen from './screens/AdminTattooScreen'
import TattooStoreScreen from './screens/TattooStoreScreen'
import TattooScreen from './screens/TattooScreen'
import TattooEditScreen from './screens/TattooEditScreen'
import AdminFaqScreen from './screens/AdminFaqScreen'
import FaqEditScreen from './screens/FaqEditScreen'
import AdminInfoScreen from './screens/AdminInfoScreen'
import ContactScreen from './screens/ContactScreen'
import TattooPaymentScreen from './screens/TattooPaymentScreen'
import ConsentScreen from './screens/ConsentScreen'
import AdminBookingScreen from './screens/AdminBookingScreen'
import ShopScreen from './screens/ShopScreen'

import FadeIn from 'react-fade-in/lib/FadeIn'

import { useDispatch, useSelector } from 'react-redux'
import { listPhotos } from './actions/photoActions'
import { getLogo } from './actions/logoActions'
import { getInfo } from './actions/infoActions'
import { getAbout } from './actions/aboutActions'
import { listFaqs } from './actions/faqActions'
import { getBooking } from './actions/bookingActions'
import Logo from './components/Logo'
import { LinkContainer } from 'react-router-bootstrap'

const App = () => {
  const dispatch = useDispatch()

  const photoList = useSelector((state) => state.photoList)
  const { loading: photoListLoading, error: photoListError, photos } = photoList

  const logoGet = useSelector((state) => state.logoGet)
  const { loading: logoGetLoading, error: logoGetError, logo } = logoGet

  const infoGet = useSelector((state) => state.infoGet)
  const { loading: infoGetLoading, error: infoGetError, info } = infoGet

  const bookingGet = useSelector((state) => state.bookingGet)
  const {
    loading: bookingGetLoading,
    error: bookingGetError,
    success: bookingGetSuccess,
    booking,
  } = bookingGet

  const aboutGet = useSelector((state) => state.aboutGet)
  const {
    success: aboutGetSuccess,
    loading: aboutGetLoading,
    error: aboutGetError,
    about,
  } = aboutGet

  const faqList = useSelector((state) => state.faqList)
  const { loading: faqListLoading, error: faqListError, faqs } = faqList

  useEffect(() => {
    dispatch(getLogo())
    dispatch(getBooking())
    dispatch(listPhotos())
    dispatch(getInfo())
    dispatch(getAbout())
    dispatch(listFaqs())
  }, [dispatch])

  return (
    <>
      <Router style={{ overflow: 'visible' }}>
        {logoGetLoading ? (
          <></>
        ) : (
          <FadeIn>
            <Row className='zero logo-row align-items-center'>
              <LinkContainer to='/'>
                <Nav.Link>
                  <Logo logo={logo} />
                </Nav.Link>
              </LinkContainer>
            </Row>
          </FadeIn>
        )}

        {logoGetLoading || infoGetLoading ? (
          <></>
        ) : (
          <FadeIn style={{ overflow: 'visible' }}>
            <Header className='logoHeader' logo={logo} info={info} />
          </FadeIn>
        )}

        {logoGetLoading ||
        photoListLoading ||
        aboutGetLoading ||
        faqListLoading ||
        infoGetLoading ||
        bookingGetLoading ? (
          <></>
        ) : (
          <main className='py-3 logoHeader main-height' id='mainScreenContent'>
            <Routes>
              <Route
                path='/'
                element={<SwiperCarousel photos={photos} />}
                exact
              />
              <Route path='/consent' element={<ConsentScreen />} />
            </Routes>
            <Container className='logoHeader' id='mainContainer'>
              <Routes>
                <Route path='/shop' element={<ShopScreen />} />

                <Route
                  path='/contact'
                  element={<ContactScreen booking={booking} />}
                />

                <Route
                  path='/'
                  element={<HomeScreen className='logoHeader' />}
                  exact
                />

                <Route path='/search/:keyword' element={<HomeScreen />} exact />

                {/* <Route
                  path='/page/:pageNumber'
                  element={<StoreScreen />}
                  exact
                /> */}

                <Route
                  path='/search/:keyword/page/:pageNumber'
                  element={<HomeScreen />}
                  exact
                />

                <Route
                  path='/portfolio'
                  element={<PortfolioScreen photos={photos} />}
                />

                {/* <Route path='/product/:id' element={<ProductScreen />} /> */}

                {/* <Route path='/tattoo/:id' element={<TattooScreen />} /> */}

                <Route path='/admin' element={<LoginScreen />} />

                {/* <Route path='/products' element={<StoreScreen />} /> */}

                {/* <Route path='/cart' element={<CartScreen />} /> */}

                {/* <Route path='/tattoos' element={<TattooStoreScreen />} /> */}

                {/* <Route
                  path='/tattoo/payment/:id'
                  element={<TattooPaymentScreen />}
                /> */}

                {/* <Route path='/profile' element={<ProfileScreen />} /> */}

                {/* <Route path='/shipping' element={<ShippingScreen />} /> */}

                {/* <Route path='/checkout' element={<PlaceOrderScreen />} /> */}

                <Route path='/about' element={<AboutScreen about={about} />} />

                <Route path='/faq' element={<FaqScreen faqs={faqs} />} />

                {/* The route above isn't working properly, possibly due to use of React Router Dom V6's updated useNavigate function, which is being used in the CartScreen instead of the outdated "history" prop. Will look into this later, right now I'll fix the issue with the route path specified below. */}

                <Route path='/login/shipping' element={<ShippingScreen />} />

                <Route path='/payment' element={<PaymentScreen />} />

                {/* <Route path='/placeorder' element={<PlaceOrderScreen />} /> */}

                <Route path='/order/:id' element={<OrderScreen />} />

                <Route path='/admin/userlist' element={<UserListScreen />} />

                <Route
                  path='/admin/user/:id/edit'
                  element={<UserEditScreen />}
                />

                <Route
                  path='/admin/panel/products'
                  element={<AdminProductScreen />}
                  exact
                />

                <Route
                  path='/admin/panel/products/:pageNumber'
                  element={<AdminProductScreen />}
                  exact
                />

                <Route
                  path='/admin/panel/orders'
                  element={<AdminOrderScreen />}
                />

                <Route
                  path='/admin/product/:id/edit'
                  element={<ProductEditScreen />}
                />

                <Route path='/admin/panel' element={<AdminPanelScreen />} />

                <Route
                  path='/admin/panel/portfolio'
                  element={<AdminPortfolioScreen photos={photos} />}
                />

                <Route
                  path='/admin/panel/about'
                  element={<AdminAboutScreen />}
                />

                <Route path='/admin/panel/logo' element={<AdminLogoScreen />} />

                <Route
                  path='/admin/panel/tattoos'
                  element={<AdminTattooScreen />}
                />

                <Route
                  path='/admin/panel/booking'
                  element={<AdminBookingScreen />}
                />

                <Route
                  path='/admin/tattoo/:id/edit'
                  element={<TattooEditScreen />}
                />

                <Route path='/admin/panel/faqs' element={<AdminFaqScreen />} />

                <Route path='/admin/faq/:id/edit' element={<FaqEditScreen />} />

                <Route path='/admin/panel/info' element={<AdminInfoScreen />} />
              </Routes>
            </Container>
          </main>
        )}

        {logoGetLoading ||
        photoListLoading ||
        aboutGetLoading ||
        faqListLoading ||
        infoGetLoading ||
        bookingGetLoading ? (
          <></>
        ) : (
          <FadeIn>
            <Footer info={info} />
          </FadeIn>
        )}
      </Router>
    </>
  )
}

export default App
