export const ABOUT_GET_REQUEST = 'ABOUT_GET_REQUEST'
export const ABOUT_GET_SUCCESS = 'ABOUT_GET_SUCCESS'
export const ABOUT_GET_FAIL = 'ABOUT_GET_FAIL'
export const ABOUT_GET_RESET = 'ABOUT_GET_RESET'

export const ABOUT_UPDATE_REQUEST = 'ABOUT_UPDATE_REQUEST'
export const ABOUT_UPDATE_SUCCESS = 'ABOUT_UPDATE_SUCCESS'
export const ABOUT_UPDATE_FAIL = 'ABOUT_UPDATE_FAIL'
export const ABOUT_UPDATE_RESET = 'ABOUT_UPDATE_RESET'

export const ABOUT_DETAILS_REQUEST = 'ABOUT_DETAILS_REQUEST'
export const ABOUT_DETAILS_SUCCESS = 'ABOUT_DETAILS_SUCCESS'
export const ABOUT_DETAILS_FAIL = 'ABOUT_DETAILS_FAIL'
