import axios from 'axios'
import {
  ABOUT_GET_REQUEST,
  ABOUT_GET_SUCCESS,
  ABOUT_GET_FAIL,
  ABOUT_GET_RESET,
  ABOUT_UPDATE_REQUEST,
  ABOUT_UPDATE_SUCCESS,
  ABOUT_UPDATE_FAIL,
  ABOUT_UPDATE_RESET,
} from '../constants/aboutConstants'

export const getAbout = () => async (dispatch) => {
  try {
    dispatch({ type: ABOUT_GET_REQUEST })

    const { data } = await axios.get('/api/about')

    dispatch({
      type: ABOUT_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ABOUT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateAbout = (about) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABOUT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put('/api/about', about, config)

    dispatch({
      type: ABOUT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ABOUT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
