import {
  INFO_GET_REQUEST,
  INFO_GET_SUCCESS,
  INFO_GET_FAIL,
  INFO_GET_RESET,
  INFO_UPDATE_REQUEST,
  INFO_UPDATE_SUCCESS,
  INFO_UPDATE_FAIL,
  INFO_UPDATE_RESET,
} from '../constants/infoConstants'

export const infoGetReducer = (state = { info: {} }, action) => {
  switch (action.type) {
    case INFO_GET_REQUEST:
      return { loading: true, info: {} }
    case INFO_GET_SUCCESS:
      return { loading: false, info: action.payload }
    case INFO_GET_FAIL:
      return { loading: false, error: action.payload }
    case INFO_GET_RESET:
      return { info: {} }
    default:
      return state
  }
}

export const infoUpdateReducer = (state = { info: {} }, action) => {
  switch (action.type) {
    case INFO_UPDATE_REQUEST:
      return { loading: true }
    case INFO_UPDATE_SUCCESS:
      return { loading: false, success: true, info: action.payload }
    case INFO_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case INFO_UPDATE_RESET:
      return { info: {} }
    default:
      return state
  }
}
