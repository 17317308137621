import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_COUNTRY_SELECT,
  CART_STATE_SELECT,
} from '../constants/cartConstants'

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      countInStock: data.countInStock,
      qty,
      type: 'product',
    },
  })

  sessionStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const cartCountrySelect = (data) => async (dispatch, getState) => {
  dispatch({
    type: CART_COUNTRY_SELECT,
    payload: data,
  })

  sessionStorage.setItem('cartCountry', JSON.stringify(data))
}

export const cartStateSelect = (data) => async (dispatch, getState) => {
  dispatch({
    type: CART_STATE_SELECT,
    payload: data,
  })

  sessionStorage.setItem('cartState', JSON.stringify(data))
}

export const addTattooToCart = (id /*, qty*/) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/tattoos/${id}`)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      exclusive: data.exclusive,
      reserved: data.reserved,
      qty: 1,
      type: 'tattoo',
    },
  })

  sessionStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  // Created "removeFromCart" action, takes in an ID, uses dispatch to dispatch the reducer, uses getState to get all non-removed items from cart to reset local storage

  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
    // "payload" is the data this action will use when dispatching the CART_REMOVE_ITEM reducer, specifically the ID of the product being removed
  })

  sessionStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  // Updates sessionStorage State now that dispatch has removed targeted item from cartItems
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  sessionStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  sessionStorage.setItem('paymentMethod', JSON.stringify(data))
}
