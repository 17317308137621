import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Taylor Moon Tattoos',
  description: '',
  keywords:
    'tattoo, tattoos, Iowa, Iowa tattoos, Taylor, Taylor Moon, Moon, taylor, taylor moon, moon, anime tattoos, manga tattoos, naruto tattoos, sailor moon tattoos, weeaboo, manga, sailor moon, anime, weeb, wapanese tattoos, otaku, otaku tattoos, custom tattoo design, custom Japanese tattoos, Japanese, cartoon, cartoon tattoos, Japanese cartoon tattoos, bleach tattoos, one piece tattoos',
}

export default Meta
