import {
  TATTOO_LIST_REQUEST,
  TATTOO_LIST_SUCCESS,
  TATTOO_LIST_FAIL,
  TATTOO_DETAILS_REQUEST,
  TATTOO_DETAILS_SUCCESS,
  TATTOO_DETAILS_FAIL,
  TATTOO_DETAILS_RESET,
  TATTOO_DELETE_REQUEST,
  TATTOO_DELETE_SUCCESS,
  TATTOO_DELETE_FAIL,
  TATTOO_CREATE_REQUEST,
  TATTOO_CREATE_SUCCESS,
  TATTOO_CREATE_FAIL,
  TATTOO_CREATE_RESET,
  TATTOO_UPDATE_REQUEST,
  TATTOO_UPDATE_SUCCESS,
  TATTOO_UPDATE_FAIL,
  TATTOO_UPDATE_RESET,
  TATTOO_SORT_REQUEST,
  TATTOO_SORT_SUCCESS,
  TATTOO_SORT_FAIL,
  TATTOO_DECREMENT_REQUEST,
  TATTOO_DECREMENT_SUCCESS,
  TATTOO_DECREMENT_FAIL,
  TATTOO_DECREMENT_RESET,
} from '../constants/tattooConstants'

export const tattooSortReducer = (state = { tattoos: [] }, action) => {
  switch (action.type) {
    case TATTOO_SORT_REQUEST:
      return { ...state, loading: true }
    case TATTOO_SORT_SUCCESS:
      return { loading: false, tattoos: action.payload }
    case TATTOO_SORT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tattooListReducer = (state = { tattoos: [] }, action) => {
  switch (action.type) {
    case TATTOO_LIST_REQUEST:
      return { loading: true, tattoos: [] }
    case TATTOO_LIST_SUCCESS:
      return {
        loading: false,
        tattoos: action.payload,
      }
    case TATTOO_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tattooDetailsReducer = (state = { tattoo: {} }, action) => {
  switch (action.type) {
    case TATTOO_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TATTOO_DETAILS_SUCCESS:
      return { loading: false, tattoo: action.payload }
    case TATTOO_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case TATTOO_DETAILS_RESET:
      return { tattoo: {} }
    default:
      return state
  }
}

export const tattooDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TATTOO_DELETE_REQUEST:
      return { loading: true }
    case TATTOO_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TATTOO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const tattooCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TATTOO_CREATE_REQUEST:
      return { loading: true }
    case TATTOO_CREATE_SUCCESS:
      return { loading: false, success: true, tattoo: action.payload }
    case TATTOO_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TATTOO_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const tattooUpdateReducer = (state = { tattoo: {} }, action) => {
  switch (action.type) {
    case TATTOO_UPDATE_REQUEST:
      return { loading: true }
    case TATTOO_UPDATE_SUCCESS:
      return { loading: false, success: true, tattoo: action.payload }
    case TATTOO_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TATTOO_UPDATE_RESET:
      return { tattoo: {} }
    default:
      return state
  }
}

export const tattooDecrementReducer = (state = { tattoo: {} }, action) => {
  switch (action.type) {
    case TATTOO_DECREMENT_REQUEST:
      return { loading: true }
    case TATTOO_DECREMENT_SUCCESS:
      return { loading: false, success: true, tattoo: action.payload }
    case TATTOO_DECREMENT_FAIL:
      return { loading: false, error: action.payload }
    case TATTOO_DECREMENT_RESET:
      return { tattoo: {} }
    default:
      return state
  }
}
