import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getInfo, updateInfo } from '../actions/infoActions'
import { INFO_UPDATE_RESET } from '../constants/infoConstants'
import { Col, Card } from 'react-bootstrap'

const AdminInfoScreen = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const infoGet = useSelector((state) => state.infoGet)
  const { loading, error, info } = infoGet

  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [instagram, setInstagram] = useState('')

  const infoUpdate = useSelector((state) => state.infoUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = infoUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: INFO_UPDATE_RESET })
      dispatch(getInfo())
      history('/admin/panel')
    } else {
      if (!info.address || !info.phone || !info.email) {
        dispatch(getInfo())
      } else {
        setAddress(info.address)
        setCity(info.city)
        setState(info.state)
        setPostalCode(info.postalCode)
        setPhone(info.phone)
        setEmail(info.email)
        setInstagram(info.instagram)
      }
    }
  }, [dispatch, info.address, info.phone, info.email, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateInfo({
        address,
        city,
        state,
        postalCode,
        phone,
        email,
        instagram,
      })
    )
  }

  return (
    <>
      <Link to='/admin/panel' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit Business Info</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='address'>
              <Form.Label className='margin-top-1point5rem'>Address</Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter business address...'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='city'>
              <Form.Label className='margin-top-1point5rem'>City</Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter city...'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='state'>
              <Form.Label className='margin-top-1point5rem'>State</Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter state...'
                value={state}
                onChange={(e) => setState(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='postalCode'>
              <Form.Label className='margin-top-1point5rem'>
                Postal Code
              </Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter postal code...'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='phone'>
              <Form.Label className='margin-top-1point5rem'>Phone</Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter phone number...'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label className='margin-top-1point5rem'>Email</Form.Label>

              <Form.Control
                type='email'
                placeholder='Enter email...'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='instagram'>
              <Form.Label className='margin-top-1point5rem'>
                Instagram
              </Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter Instagram link...'
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default AdminInfoScreen
