import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Card, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import {
  listProducts,
  deleteProduct,
  createProduct,
  sortProducts,
} from '../actions/productActions'
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_LIST_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd'

const AdminProductScreen = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const [sortedProducts, setSortedProducts] = useState([])

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate

  const productSort = useSelector((state) => state.productSort)
  const {
    loading: loadingSort,
    error: errorSort,
    success: successSort,
    products: newSortedProducts,
  } = productSort

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })
    dispatch({ type: PRODUCT_DETAILS_RESET })
    dispatch(listProducts())

    if (!userInfo.isAdmin) {
      history('/')
    }

    if (successCreate) {
      history(`/admin/product/${createdProduct._id}/edit`)
    } else {
      // dispatch(listProducts())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    successSort,
  ])

  useEffect(() => {
    if (!loading) {
      setSortedProducts(products)
    }
  }, [loading])

  useEffect(() => {
    console.log(sortedProducts)
  }, [sortedProducts])

  const deleteHandler = (id) => {
    if (
      window.confirm(
        `WARNING! THIS ACTION CANNOT BE UNDONE! Are you sure you want to delete this product?`
      )
    ) {
      dispatch(deleteProduct(id))
    }
  }

  const createProductHandler = () => {
    dispatch(createProduct())
  }

  const productSortHandler = () => {
    dispatch(sortProducts(sortedProducts))
    history('/admin/panel')
  }

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newSortedProducts = swap(sortedProducts, sourceIndex, targetIndex)
    setSortedProducts(newSortedProducts)
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Edit Products</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Link to='/admin/panel' className='btn btn-light my-3'>
            Go Back
          </Link>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createProductHandler}>
            <i className='fas fa-plus'></i> Create Product
          </Button>
        </Col>

        <Col className='saveSortedProductsButton text-center my-auto'>
          <Button
            // variant='primary'
            className='my-3'
            onClick={() => productSortHandler()}
          >
            Save New Product Arrangement
          </Button>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

      {loading || sortedProducts === null || sortedProducts.length === 0 ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id='items'
            boxesPerRow={6}
            rowHeight={410}
            style={{ height: 410 * Math.ceil(sortedProducts.length / 6) }}
          >
            {sortedProducts.map((product) => (
              <GridItem key={product._id} className='grid-item'>
                <Card className='rounded'>
                  <Card.Img
                    src={product.image}
                    variant='top'
                    className='product-card-image'
                    onDragStart={(e) => {
                      e.preventDefault()
                    }}
                  />

                  <ListGroup variant='flush' className='product-card'>
                    <ListGroup.Item className='product-card product-name input-font'>
                      {product.name}
                    </ListGroup.Item>
                    <ListGroup.Item className='product-card product-name input-font'>
                      <Row>
                        <Col
                          className='input-font'
                          style={{
                            borderRight: '1px solid #FEF5D3',
                          }}
                        >
                          ${product.price.toFixed(2)}
                        </Col>
                        <Col
                          className='input-font'
                          style={{
                            borderLeft: '1px solid #FEF5D3',
                            textAlign: 'center',
                          }}
                        >
                          {product.countInStock} left
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
                <LinkContainer to={`/admin/product/${product._id}/edit`}>
                  <Button variant='light' className='btn-sm'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </LinkContainer>

                <Button
                  variant='danger'
                  className='btn-sm'
                  onClick={() => deleteHandler(product._id)}
                >
                  <i className='fas fa-trash'></i>
                </Button>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </>
  )
}

export default AdminProductScreen
