import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import SwiperCarousel from '../components/SwiperCarouselV2'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
import { listPhotos } from '../actions/photoActions'
import { useParams } from 'react-router-dom'

const HomeScreen = () => {
  // const { keyword } = useParams()

  // const { pageNumber } = useParams() || 1

  const dispatch = useDispatch()

  // const productList = useSelector((state) => state.productList)

  // const { loading, error, products, page, pages } = productList

  useEffect(() => {
    // dispatch(listProducts(keyword, pageNumber))
    // Dispatch listPhotos action, filling our state with the payload
    // dispatch(listPhotos())
  }, [dispatch /*, keyword, pageNumber*/])

  return (
    <>
      <Meta />

      {/* {!keyword ? (
        <ProductCarousel style={{ width: '100vw' }} />
      ) : (
        <Link to='/' className='btn btn-light'>
          Go Back
        </Link>
      )} */}

      {/* <h1>
        <span className='bring-forward'>Latest Products</span>
      </h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {products.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>

          <Paginate
            pages={pages}
            page={page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )} */}
    </>
  )
}

export default HomeScreen
