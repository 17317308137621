import axios from 'axios'
import {
  LOGO_GET_REQUEST,
  LOGO_GET_SUCCESS,
  LOGO_GET_FAIL,
  LOGO_UPDATE_REQUEST,
  LOGO_UPDATE_SUCCESS,
  LOGO_UPDATE_FAIL,
} from '../constants/logoConstants'

export const getLogo = () => async (dispatch) => {
  try {
    dispatch({ type: LOGO_GET_REQUEST })

    const { data } = await axios.get('/api/logo')

    dispatch({
      type: LOGO_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LOGO_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLogo = (logo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOGO_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/logo`, logo, config)

    dispatch({
      type: LOGO_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LOGO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
