import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listOrders, deleteOrder } from '../actions/orderActions'

const OrderListScreen = () => {
  const dispatch = useDispatch()

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList

  const orderDelete = useSelector((state) => state.orderDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders())
    } else {
      history('/login')
    }

    if (successDelete) {
      dispatch(listOrders())
    }
  }, [dispatch, history, userInfo, successDelete])

  const deleteHandler = (id) => {
    if (
      window.confirm(
        `WARNING! THIS ACTION CANNOT BE UNDONE! Are you sure you want to delete this order?`
      )
    ) {
      dispatch(deleteOrder(id))
    }
  }

  return (
    <>
      <h1>Orders</h1>

      <Link to='/admin/panel' className='btn btn-light my-3'>
        Go Back
      </Link>

      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>NAME</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>SENT</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {orders
              .filter((order) => !order.isDelivered)
              .reverse()
              .map((order) => (
                <tr key={order._id}>
                  <td>{order.shippingAddress.name}</td>
                  <td>
                    {new Date(order.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      // timeZoneName: 'short',
                      timeZone: 'America/Chicago',
                    })}
                  </td>
                  <td>${order.totalPrice}</td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>

                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button variant='light' className='btn-sm'>
                        Details
                      </Button>
                    </LinkContainer>

                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(order._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
            {orders
              .filter((order) => order.isDelivered)
              .reverse()
              .map((order) => (
                <tr key={order._id}>
                  <td>{order.shippingAddress.name}</td>
                  <td>
                    {new Date(order.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      // timeZoneName: 'short',
                      timeZone: 'America/Chicago',
                    })}
                  </td>
                  <td>${order.totalPrice}</td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>

                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button variant='light' className='btn-sm'>
                        Details
                      </Button>
                    </LinkContainer>

                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(order._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default OrderListScreen
