import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getBooking, updateBooking } from '../actions/bookingActions'
import { BOOKING_UPDATE_RESET } from '../constants/bookingConstants'
import { Col, Card } from 'react-bootstrap'

const AdminBookingScreen = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const bookingGet = useSelector((state) => state.bookingGet)
  const { loading, error, booking } = bookingGet

  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(true)

  const bookingUpdate = useSelector((state) => state.bookingUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = bookingUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BOOKING_UPDATE_RESET })
      dispatch(getBooking())
      history('/admin/panel')
    } else {
      if (!booking.message) {
        dispatch(getBooking())
      } else {
        setOpen(booking.open)
        setMessage(booking.message)
      }
    }
  }, [dispatch, booking.open, booking.message, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateBooking({
        open,
        message,
      })
    )
  }

  return (
    <>
      <Link to='/admin/panel' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit Business Info</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='message'>
              <Form.Label className='margin-top-1point5rem'>
                Books Closed Message :
              </Form.Label>

              <Form.Control
                as='textarea'
                rows={10}
                type='text'
                placeholder='Enter message to be displayed when your books are closed...'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='open'>
              <Form.Label className='margin-top-1point5rem'>
                Are your books currently open?
              </Form.Label>

              <Form.Select
                value={open}
                onChange={(e) => setOpen(e.target.value)}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Select>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Update Booking Status
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default AdminBookingScreen
