import {
  LOGO_GET_REQUEST,
  LOGO_GET_SUCCESS,
  LOGO_GET_FAIL,
  LOGO_UPDATE_REQUEST,
  LOGO_UPDATE_SUCCESS,
  LOGO_UPDATE_FAIL,
  LOGO_UPDATE_RESET,
} from '../constants/logoConstants'

export const logoGetReducer = (state = { logo: {} }, action) => {
  switch (action.type) {
    case LOGO_GET_REQUEST:
      return { loading: true, logo: {} }
    case LOGO_GET_SUCCESS:
      return { loading: false, logo: action.payload }
    case LOGO_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const logoUpdateReducer = (state = { logo: {} }, action) => {
  switch (action.type) {
    case LOGO_UPDATE_REQUEST:
      return { loading: true }
    case LOGO_UPDATE_SUCCESS:
      return { loading: false, success: true, logo: action.payload }
    case LOGO_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case LOGO_UPDATE_RESET:
      return { logo: {} }
    default:
      return state
  }
}
