import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Row,
  Col,
  Offcanvas,
  Image,
} from 'react-bootstrap'
import { logout } from '../actions/userActions'
import { getLogo } from '../actions/logoActions'
import Loader from './Loader'
import Message from './Message'
import Logo from './Logo'
import FadeIn from 'react-fade-in'

const Header = ({ logo, info }) => {
  const location = useLocation()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  return (
    <header style={{ overflow: 'visible' }}>
      <Navbar
        className='zero align-items-center'
        id='navbar-align'
        variant='dark'
        expand='md'
        collapseOnSelect
        style={{ overflow: 'visible' }}
      >
        <Container
          className='zero'
          id='logo-container'
          style={{ overflow: 'visible' }}
        >
          <Col
            className='align-items-center'
            id='logo-col'
            style={{ overflow: 'visible' }}
          >
            <Row className='zero navbar-row' id='navbar-row'>
              <Navbar.Toggle
                id='navbar-toggle-button'
                aria-controls='basic-navbar-nav'
                className='shadow-none'
              />

              <Navbar.Offcanvas id='basic-navbar-nav' placement='start'>
                {/* <SearchBox history={history} /> */}

                <Offcanvas.Header id='mobileNavHeader' className='mobileOnly'>
                  <LinkContainer to='/' style={{ height: '100%' }}>
                    <Nav.Link active={location.pathname === '/'}>
                      <Image
                        src={logo && logo.image}
                        fluid='true'
                        className='zero big-logo align-items-center'
                        alt='Taylor Moon Tattoos Logo'
                        id='mobileNavLogo'
                      />
                    </Nav.Link>
                  </LinkContainer>
                </Offcanvas.Header>
                <div className='mobileOnly' id='mobileNavDivider'></div>
                <Nav className='zero' id='main-navbar'>
                  <LinkContainer
                    to='/'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/'}>
                      <div className='navbar-link'>Home</div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to='/portfolio'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/portfolio'}>
                      <div className='navbar-link'>Portfolio</div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to='/about'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/about'}>
                      <div className='navbar-link'>About</div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to='/shop'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/shop'}>
                      <div className='navbar-link'>Shop</div>
                    </Nav.Link>
                  </LinkContainer>

                  {/* <NavDropdown
                    title='Shop'
                    id='mainNavDropdown'
                    className='zero admin-menu navbar-link'
                  >
                    <LinkContainer to='/tattoos' className='navbar-link'>
                      <NavDropdown.Item>
                        <div className='swiperNavbarZoom dropDownHover'>
                          PreDrawn Designs
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>

                    <LinkContainer to='/products' className='navbar-link'>
                      <NavDropdown.Item>
                        <div className='swiperNavbarZoom dropDownHover'>
                          Products
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer> */}

                  {/* <LinkContainer
                      to='/admin/orderlist'
                      className='navbar-link'
                    >
                      <NavDropdown.Item className='navbar-link'>
                        <div className='swiperNavbarZoom dropDownHover'>
                          Find Order
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer> */}
                  {/* </NavDropdown> */}

                  {/* <LinkContainer
                    to='/products'
                    className='mainNavbarZoom mobileOnly'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/products'}>
                      <div className='navbar-link'>Shop</div>
                    </Nav.Link>
                  </LinkContainer> */}

                  {/* <LinkContainer
                    to='/tattoos'
                    className='mainNavbarZoom mobileOnly'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/tattoos'}>
                      <div className='navbar-link'>Flash Tattoos</div>
                    </Nav.Link>
                  </LinkContainer> */}

                  <LinkContainer
                    to='/faq'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/faq'}>
                      <div className='navbar-link'>FAQ</div>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to='/contact'
                    className='mainNavbarZoom'
                    style={{ width: '100%' }}
                  >
                    <Nav.Link active={location.pathname === '/contact'}>
                      <div className='navbar-link'>Booking</div>
                    </Nav.Link>
                  </LinkContainer>

                  {cartItems.length > 0 && (
                    <LinkContainer
                      to='/cart'
                      id='desktopCartButton'
                      title='Go to Cart'
                    >
                      <Nav.Link active={location.pathname === '/cart'}>
                        <Col className='navFooterCol'>
                          {cartItems.length !== 0 && (
                            <div
                              style={{
                                position: 'relative',
                                width: '0',
                                height: '0',
                              }}
                            >
                              <div
                                id='desktopCartCounter'
                                style={{
                                  position: 'absolute',
                                  // display: 'inline',
                                  // paddingLeft: '3px',
                                  // paddingRight: '2px',
                                  // paddingTop: '0px',
                                  margin: '0px',
                                  padding: '0px',
                                  // color: '#FED9E0',
                                  zIndex: '5',
                                  borderRadius: '50%',
                                  // backgroundColor: '#1A1A1A',
                                  lineHeight: '1.25rem',
                                  aspectRatio: '1/1',
                                  height: '1.4rem',
                                  top: '30px',
                                  left: '31px',
                                  // border: '2px solid #FED9E0',
                                  fontSize: '0.65rem',
                                }}
                              >
                                {cartItems.reduce(
                                  (acc, item) => acc + Number(item.qty),
                                  0
                                )}
                              </div>
                            </div>
                          )}
                          <svg
                            // position='fixed'
                            width='41px'
                            height='41px'
                            viewBox='0 -1.5 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            transform='matrix(-1, 0, 0, 1, 0, 0)'
                          >
                            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                            <g
                              id='SVGRepo_tracerCarrier'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></g>
                            <g id='SVGRepo_iconCarrier'>
                              {' '}
                              <path
                                d='M2 3H4.5L6.5 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM6.07142 14H18L21 5H4.78571M11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z'
                                stroke='#FEF5D3'
                                strokeWidth='1.75'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              ></path>{' '}
                            </g>
                          </svg>
                        </Col>
                      </Nav.Link>
                    </LinkContainer>
                  )}

                  {/* <LinkContainer
                    to='/'
                    className='navbar-link mainNavbarZoom mobileOnly'
                  >
                    <Nav.Link>Find My Order</Nav.Link>
                  </LinkContainer> */}

                  <div className='mobileOnly' id='navFooterContainer'>
                    <Row id='mobileNavFooter' className='mobileOnly'>
                      <a
                        href={'mailto:' + info.email}
                        style={{ width: 'auto', color: '#FFFFFF00' }}
                      >
                        <Col className='navFooterCol'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            width='37px'
                            height='37px'
                            color='#FED9E0'
                            fill='#FED9E0'
                          >
                            <path d='M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z' />
                          </svg>
                        </Col>
                      </a>
                      <a
                        href={info && info.instagram}
                        style={{ width: 'auto', color: '#FFFFFF00' }}
                      >
                        <Col className='navFooterCol'>
                          <svg
                            width='39px'
                            height='39px'
                            viewBox='0 0 24 24'
                            color='#FED9E0'
                            fill='#FED9E0'
                            xmlns='http://www.w3.org/2000/svg'
                            stroke='#FED9E0'
                            strokeWidth='0'
                          >
                            <path
                              id='Vector'
                              d='M 12 2.982 c 2.937 0 3.285 0.011 4.445 0.064 a 6.087 6.087 0 0 1 2.042 0.379 a 3.408 3.408 0 0 1 1.265 0.823 a 3.408 3.408 0 0 1 0.823 1.265 a 6.087 6.087 0 0 1 0.379 2.042 c 0.053 1.16 0.064 1.508 0.064 4.445 s -0.011 3.285 -0.064 4.445 a 6.087 6.087 0 0 1 -0.379 2.042 a 3.643 3.643 0 0 1 -2.088 2.088 a 6.087 6.087 0 0 1 -2.042 0.379 c -1.16 0.053 -1.508 0.064 -4.445 0.064 s -3.285 -0.011 -4.445 -0.064 a 6.087 6.087 0 0 1 -2.043 -0.379 a 3.408 3.408 0 0 1 -1.264 -0.823 a 3.408 3.408 0 0 1 -0.823 -1.265 a 6.087 6.087 0 0 1 -0.379 -2.042 c -0.053 -1.16 -0.064 -1.508 -0.064 -4.445 s 0.011 -3.285 0.064 -4.445 a 6.087 6.087 0 0 1 0.379 -2.042 a 3.408 3.408 0 0 1 0.823 -1.265 a 3.408 3.408 0 0 1 1.265 -0.823 a 6.087 6.087 0 0 1 2.042 -0.379 c 1.16 -0.053 1.508 -0.064 4.445 -0.064 M 12 1 c -2.987 0 -3.362 0.013 -4.535 0.066 a 8.074 8.074 0 0 0 -2.67 0.511 a 5.392 5.392 0 0 0 -1.949 1.27 a 5.392 5.392 0 0 0 -1.269 1.948 a 8.074 8.074 0 0 0 -0.51 2.67 C 1.012 8.638 1 9.013 1 12 s 0.013 3.362 0.066 4.535 a 8.074 8.074 0 0 0 0.511 2.67 a 5.392 5.392 0 0 0 1.27 1.949 a 5.392 5.392 0 0 0 1.948 1.269 a 8.074 8.074 0 0 0 2.67 0.51 C 8.638 22.988 9.013 23 12 23 s 3.362 -0.013 4.535 -0.066 a 8.074 8.074 0 0 0 2.67 -0.511 a 5.625 5.625 0 0 0 3.218 -3.218 a 8.074 8.074 0 0 0 0.51 -2.67 C 22.988 15.362 23 14.987 23 12 s -0.013 -3.362 -0.066 -4.535 a 8.074 8.074 0 0 0 -0.511 -2.67 a 5.392 5.392 0 0 0 -1.27 -1.949 a 5.392 5.392 0 0 0 -1.948 -1.269 a 8.074 8.074 0 0 0 -2.67 -0.51 C 15.362 1.012 14.987 1 12 1 Z m 0 5.351 A 5.649 5.649 0 1 0 17.649 12 A 5.649 5.649 0 0 0 12 6.351 Z m 0 9.316 A 3.667 3.667 0 1 1 15.667 12 A 3.667 3.667 0 0 1 12 15.667 Z m 5.872 -10.859 a 1.32 1.32 0 1 0 1.32 1.32 a 1.32 1.32 0 0 0 -1.32 -1.32 Z'
                            ></path>
                          </svg>
                        </Col>
                      </a>
                      <LinkContainer
                        to='/shop'
                        id='navFooterLink'
                        title='Go to Shop'
                      >
                        <Nav.Link active={location.pathname === '/shop'}>
                          <Col className='navFooterCol'>
                            {cartItems.length !== 0 && (
                              <div
                                style={{
                                  position: 'relative',
                                  width: '0',
                                  height: '0',
                                }}
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    // display: 'inline',
                                    // paddingLeft: '3px',
                                    // paddingRight: '2px',
                                    // paddingTop: '0px',
                                    margin: '0px',
                                    padding: '0px',
                                    color: '#FED9E0',
                                    zIndex: '5',
                                    borderRadius: '50%',
                                    backgroundColor: '#1A1A1A',
                                    lineHeight: '1.25rem',
                                    aspectRatio: '1/1',
                                    height: '1.4rem',
                                    top: '12px',
                                    left: '31px',
                                    border: '2px solid #FED9E0',
                                    fontSize: '0.65rem',
                                  }}
                                >
                                  {cartItems.reduce(
                                    (acc, item) => acc + Number(item.qty),
                                    0
                                  )}
                                </div>
                              </div>
                            )}
                            <svg
                              // position='fixed'
                              width='41px'
                              height='41px'
                              viewBox='0 -1.5 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                              transform='matrix(-1, 0, 0, 1, 0, 0)'
                            >
                              <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                              <g
                                id='SVGRepo_tracerCarrier'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              ></g>
                              <g id='SVGRepo_iconCarrier'>
                                {' '}
                                <path
                                  d='M2 3H4.5L6.5 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM6.07142 14H18L21 5H4.78571M11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z'
                                  stroke='#FED9E0'
                                  strokeWidth='1.75'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                ></path>{' '}
                              </g>
                            </svg>
                          </Col>
                        </Nav.Link>
                      </LinkContainer>
                    </Row>
                  </div>
                </Nav>
              </Navbar.Offcanvas>
            </Row>
          </Col>
        </Container>
      </Navbar>
    </header>
  )
}
export default Header
