import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getAbout } from '../actions/aboutActions'

const PanelScreen = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    if (!userInfo) {
      history('/admin')
    }
  }, [history, userInfo])

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Admin Panel</h1>
        </Col>
      </Row>

      <div className='d-grid gap-4'>
        <Row>
          <Col>
            <LinkContainer to='/admin/panel/orders'>
              <Button variant='dark' size='lg' className='w-100'>
                View Orders
              </Button>
            </LinkContainer>
          </Col>

          <Col>
            <LinkContainer to='/admin/panel/about'>
              <Button variant='dark' size='lg' className='w-100'>
                Update About Page
              </Button>
            </LinkContainer>
          </Col>
        </Row>

        <Row>
          <Col>
            <LinkContainer to='/admin/panel/products'>
              <Button variant='dark' size='lg' className='w-100'>
                Edit Products
              </Button>
            </LinkContainer>
          </Col>

          <Col>
            <LinkContainer to='/admin/panel/tattoos'>
              <Button variant='dark' size='lg' className='w-100'>
                Edit Flash Tattoos
              </Button>
            </LinkContainer>
          </Col>
        </Row>

        <Row>
          <Col>
            <LinkContainer to='/admin/panel/portfolio'>
              <Button variant='dark' size='lg' className='w-100'>
                Edit Portfolio
              </Button>
            </LinkContainer>
          </Col>

          <Col>
            <LinkContainer to='/admin/panel/faqs'>
              <Button variant='dark' size='lg' className='w-100'>
                Edit FAQs
              </Button>
            </LinkContainer>
          </Col>
        </Row>

        <Row>
          <Col>
            <LinkContainer to='/admin/panel/info'>
              <Button variant='dark' size='lg' className='w-100'>
                Update Shop Info
              </Button>
            </LinkContainer>
          </Col>

          <Col>
            <LinkContainer to='/admin/panel/booking'>
              <Button variant='dark' size='lg' className='w-100'>
                Update Booking Status
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default PanelScreen
