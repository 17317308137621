import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Table,
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  Card,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import {
  listTattoos,
  deleteTattoo,
  createTattoo,
  sortTattoos,
} from '../actions/tattooActions'
import {
  TATTOO_CREATE_RESET,
  TATTOO_DETAILS_RESET,
} from '../constants/tattooConstants'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd'

const AdminTattooScreen = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const [sortedTattoos, setSortedTattoos] = useState([])

  const tattooList = useSelector((state) => state.tattooList)
  const { loading, error, tattoos } = tattooList

  const tattooDelete = useSelector((state) => state.tattooDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = tattooDelete

  const tattooCreate = useSelector((state) => state.tattooCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    tattoo: createdTattoo,
  } = tattooCreate

  const tattooSort = useSelector((state) => state.tattooSort)
  const {
    loading: loadingSort,
    error: errorSort,
    success: successSort,
    tattoos: newSortedTattoos,
  } = tattooSort

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    dispatch({ type: TATTOO_CREATE_RESET })
    dispatch({ type: TATTOO_DETAILS_RESET })
    dispatch(listTattoos())

    if (!userInfo.isAdmin) {
      history('/')
    }

    if (successCreate) {
      history(`/admin/tattoo/${createdTattoo._id}/edit`)
    } else {
      // dispatch(listTattoos())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdTattoo,
    successSort,
  ])

  useEffect(() => {
    if (!loading) {
      setSortedTattoos(tattoos)
    }
  }, [loading])

  useEffect(() => {
    console.log(sortedTattoos)
  }, [sortedTattoos])

  const deleteHandler = (id) => {
    if (
      window.confirm(
        `WARNING! THIS ACTION CANNOT BE UNDONE! Are you sure you want to delete this tattoo design?`
      )
    ) {
      dispatch(deleteTattoo(id))
    }
  }

  const createTattooHandler = () => {
    dispatch(createTattoo())
  }

  const tattooSortHandler = () => {
    dispatch(sortTattoos(sortedTattoos))
    history('/admin/panel')
  }

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newSortedTattoos = swap(sortedTattoos, sourceIndex, targetIndex)
    setSortedTattoos(newSortedTattoos)
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Edit Flash Tattoos</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Link to='/admin/panel' className='btn btn-light my-3'>
            Go Back
          </Link>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createTattooHandler}>
            <i className='fas fa-plus'></i> Create Tattoo
          </Button>
        </Col>
        <Col></Col>

        <Col className='saveSortedTattoosButton text-center my-auto'>
          <Button className='my-3' onClick={() => tattooSortHandler()}>
            Save New Tattoo Arrangement
          </Button>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

      {loading || sortedTattoos === null || sortedTattoos.length === 0 ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id='items'
            boxesPerRow={6}
            rowHeight={440}
            style={{ height: 440 * Math.ceil(sortedTattoos.length / 6) }}
          >
            {sortedTattoos.map((tattoo) => (
              <GridItem key={tattoo._id} className='grid-item'>
                <Card className='rounded'>
                  <Card.Img
                    src={tattoo.image}
                    variant='top'
                    className='product-card-image'
                    onDragStart={(e) => {
                      e.preventDefault()
                    }}
                  />

                  <ListGroup variant='flush' className='product-card'>
                    <ListGroup.Item className='product-card product-name input-font'>
                      {tattoo.name}
                    </ListGroup.Item>
                    <ListGroup.Item className='product-card product-name'>
                      <Row>
                        <Col className='input-font'>${tattoo.price}</Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className='product-card product-name'>
                      <Row>
                        <Col
                          className='input-font'
                          style={{
                            borderRight: '1px solid #FEF5D3',
                            textAlign: 'center',
                          }}
                        >
                          E: {tattoo.exclusive ? <>Yes</> : <>No</>}
                        </Col>
                        <Col
                          className='input-font'
                          style={{
                            borderLeft: '1px solid #FEF5D3',
                            textAlign: 'center',
                          }}
                        >
                          {tattoo.quantity}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
                <LinkContainer to={`/admin/tattoo/${tattoo._id}/edit`}>
                  <Button variant='light' className='btn-sm'>
                    <i className='fas fa-edit'></i>
                  </Button>
                </LinkContainer>

                <Button
                  variant='danger'
                  className='btn-sm'
                  onClick={() => deleteHandler(tattoo._id)}
                >
                  <i className='fas fa-trash'></i>
                </Button>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </>
  )
}

export default AdminTattooScreen
