import React, { useEffect, useRef } from 'react'

const ContactScreen = ({ booking }) => {
  const iframeRef = useRef(null)

  useEffect(() => {
    const handleMessage = (e) => {
      if (typeof e.data === 'object') return
      const args = e.data.split(':')
      const iframe = iframeRef.current
      if (!iframe) return

      switch (args[0]) {
        case 'setHeight':
          let messageHeight = Number(args[1])
          messageHeight *= 1.16

          iframe.style.height = messageHeight + 'px'

          break
        default:
          break
      }
    }

    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [])

  return (
    <>
      {!booking.open ? (
        <>
          <h1 className='text-align-center'>BOOKING</h1>

          <p className='text-align-center'>{booking.message}</p>
        </>
      ) : (
        <iframe
          ref={iframeRef}
          id='JotFormIFrame-230977830551158'
          title='Tattoo Request Form'
          allowtransparency='true'
          allowFullScreen={true}
          allow='geolocation; microphone; camera'
          src='https://form.jotform.com/230977830551158'
          frameBorder='0'
          style={{
            minWidth: '100%',
            maxWidth: '100%',
            // height: 'auto',
            border: 'none',
          }}
          scrolling='no'
          loading='lazy'
        />
      )}
    </>
  )
}

export default ContactScreen
