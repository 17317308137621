import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const Photo = ({ photo }) => {
  return (
    <Card className='photo-card rounded'>
      <Card.Img
        src={photo.image}
        variant='top'
        // className='photo-card-image expandPortfolioPhoto'
        className='photo-card-image'
        onDragStart={(e) => {
          e.preventDefault()
        }}
        loading='lazy'
      />
    </Card>
  )
}

export default Photo
