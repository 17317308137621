import React, { useEffect, useRef } from 'react'

const ConsentScreen = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
        // paddingBottom: '160%',
        marginTop: '10px',
      }}
    >
      <iframe
        title='Strawberry Moon Tattoo Consent Form'
        src='https://www.jotform.com/sign/231035193359051/invite/01h0h6m2mvbd847a082b70ddab?signEmbed=1'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100dvh',
          width: '100%',
          border: 0,
          zIndex: 1000,
        }}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default ConsentScreen
