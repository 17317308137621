import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Alert,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../constants/orderConstants'

const OrderScreen = () => {
  const { id } = useParams() // Replacing match prop
  const orderId = id

  const history = useNavigate()

  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const orderCreate = useSelector((state) => state.orderCreate)
  const { success: successCreate } = orderCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  if (!loading) {
    // Lazy Fix for State Mutation Error
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    )
    // Lazy Fix for State Mutation Error

    order.totalPrice = addDecimals(order.totalPrice)
  }

  useEffect(() => {
    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')

      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.async = true

      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })

      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [order, orderId, dispatch, successPay, successDeliver, history, userInfo])

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult)

    dispatch(payOrder(orderId, paymentResult))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  const [show, setShow] = useState(true)

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      {show && (
        <Alert
          variant='info'
          style={{ marginTop: '16px', width: '100%' }}
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading
            style={{ textAlign: 'center', overflowWrap: 'break-word' }}
          >
            YOUR ORDER HAS BEEN PLACED
          </Alert.Heading>
          <br />
          <p style={{ textAlign: 'center', overflowWrap: 'break-word' }}>
            A receipt has been emailed to {order.shippingAddress.email},
            including a link to view your order details and shipment status. Be
            sure to check your Junk/Spam folder if you do not see it in your
            Inbox.
          </p>
        </Alert>
      )}

      <h1 style={{ textAlign: 'center', overflowWrap: 'break-word' }}>
        Order {order._id}
      </h1>

      {userInfo && userInfo.isAdmin && (
        <Link to='/admin/panel/orders' className='btn btn-light my-3'>
          Go Back
        </Link>
      )}

      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Alert variant='success' style={{ textAlign: 'center' }}>
                Your Order was placed on <br />
                {new Date(order.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                  timeZone: 'America/Chicago',
                })}
                <br />
                <br />
                If this is an order to reserve a pre-drawn design, Taylor will
                contact you shortly to discuss setting up an appointment.
              </Alert>

              <h2 style={{ textAlign: 'center' }}>Shipping</h2>

              <p style={{ overflowWrap: 'break-word' }}>
                <strong>Name: </strong> {order.shippingAddress.name}
              </p>

              <p style={{ overflowWrap: 'break-word' }}>
                <strong>Email: </strong>
                <a href={`mailto:${order.shippingAddress.email}`}>
                  {order.shippingAddress.email}
                </a>
              </p>

              <p style={{ overflowWrap: 'break-word' }}>
                <strong>Phone: </strong>
                <a href={`tel:${order.shippingAddress.phone}`}>
                  {formatPhoneNumber(order.shippingAddress.phone)}
                </a>
              </p>

              <p style={{ overflowWrap: 'break-word' }}>
                <strong>Address: </strong>
                <br />
                {!order.shippingAddress.address_2
                  ? order.shippingAddress.address_1
                  : order.shippingAddress.address_1 +
                    ', ' +
                    order.shippingAddress.address_2}
                ,
                <br />
                {order.shippingAddress.city},{' '}
                {order.shippingAddress.addressState},{' '}
                {order.shippingAddress.postalCode}
                <br />
                {order.shippingAddress.addressCountry}
              </p>

              {order.isDelivered ? (
                <Message
                  variant='success'
                  style={{ overflowWrap: 'break-word' }}
                >
                  Shipped on{' '}
                  {new Date(order.deliveredAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZoneName: 'short',
                    timeZone: 'America/Chicago',
                  })}
                </Message>
              ) : (
                <Message variant='danger'>Not Yet Shipped</Message>
              )}
            </ListGroup.Item>

            {/* <ListGroup.Item>
              <h2>Payment Method</h2>

              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>

              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item> */}

            <ListGroup.Item>
              <h2>Order Items</h2>

              {order.orderItems.length === 0 ? (
                <Message>Order is empty.</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>

                        <Col md={4}>
                          {item.qty} x ${item.price} = $
                          {Math.round(100 * (item.qty * item.price)) / 100}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 style={{ textAlign: 'center' }}>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>

                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>

                  <Col>${order.shippingPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>

                  <Col>${order.taxPrice.toFixed(2)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>

                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              {/* {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )} */}

              {loadingDeliver && <Loader />}

              {userInfo && userInfo.isAdmin && !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block'
                    onClick={deliverHandler}
                  >
                    Mark As Sent
                  </Button>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
