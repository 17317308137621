import {
  ABOUT_GET_REQUEST,
  ABOUT_GET_SUCCESS,
  ABOUT_GET_FAIL,
  ABOUT_GET_RESET,
  ABOUT_UPDATE_REQUEST,
  ABOUT_UPDATE_SUCCESS,
  ABOUT_UPDATE_FAIL,
  ABOUT_UPDATE_RESET,
} from '../constants/aboutConstants'

export const aboutGetReducer = (state = { about: {} }, action) => {
  switch (action.type) {
    case ABOUT_GET_REQUEST:
      return { loading: true, about: {} }
    case ABOUT_GET_SUCCESS:
      return { success: true, loading: false, about: action.payload }
    case ABOUT_GET_FAIL:
      return { loading: false, error: action.payload }
    case ABOUT_GET_RESET:
      return { about: {} }
    default:
      return state
  }
}

export const aboutUpdateReducer = (state = { about: {} }, action) => {
  switch (action.type) {
    case ABOUT_UPDATE_REQUEST:
      return { loading: true }
    case ABOUT_UPDATE_SUCCESS:
      return { loading: false, success: true, about: action.payload }
    case ABOUT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case ABOUT_UPDATE_RESET:
      return { about: {} }
    default:
      return state
  }
}
