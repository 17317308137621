import {
  BOOKING_GET_REQUEST,
  BOOKING_GET_SUCCESS,
  BOOKING_GET_FAIL,
  BOOKING_GET_RESET,
  BOOKING_UPDATE_REQUEST,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_UPDATE_RESET,
} from '../constants/bookingConstants'

export const bookingGetReducer = (state = { booking: {} }, action) => {
  switch (action.type) {
    case BOOKING_GET_REQUEST:
      return { loading: true, booking: {} }
    case BOOKING_GET_SUCCESS:
      return { success: true, loading: false, booking: action.payload }
    case BOOKING_GET_FAIL:
      return { loading: false, error: action.payload }
    case BOOKING_GET_RESET:
      return { booking: {} }
    default:
      return state
  }
}

export const bookingUpdateReducer = (state = { booking: {} }, action) => {
  switch (action.type) {
    case BOOKING_UPDATE_REQUEST:
      return { loading: true }
    case BOOKING_UPDATE_SUCCESS:
      return { loading: false, success: true, booking: action.payload }
    case BOOKING_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case BOOKING_UPDATE_RESET:
      return { booking: {} }
    default:
      return state
  }
}
