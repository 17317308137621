import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = ({ info }) => {
  return (
    <footer>
      <Container style={{ maxWidth: '100%' }}>
        {/* <Row style={{ maxWidth: 'none' }}>
          <Col
            className='text-center py-3 footer px-0 mx-3'
            // style={{ borderTop: '1px solid #21534A' }}
          >
            <p style={{ marginBottom: '0.5rem' }}>Strawberry Moon Tattoo</p>
            <p style={{ marginBottom: '0.5rem' }}>{info.address}</p>
            <p style={{ marginBottom: '0.5rem' }}>
              {info.city}, {info.state}
            </p>
            <p style={{ marginBottom: '0.5rem' }}>{info.postalCode}</p>
          </Col>
        </Row> */}
        <Row>
          <Col className='text-center py-3 footer copyright-bar'>
            Copyright &copy; Taylor Moon Tattoos
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
