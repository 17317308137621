import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Image, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import { listFaqs, deleteFaq, createFaq, sortFaqs } from '../actions/faqActions'
import {
  FAQ_CREATE_RESET,
  FAQ_DETAILS_RESET,
  FAQ_LIST_RESET,
} from '../constants/faqConstants'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd'

const AdminFaqScreen = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const [sortedFaqs, setSortedFaqs] = useState([])

  const faqList = useSelector((state) => state.faqList)
  const { loading, error, faqs } = faqList

  const faqDelete = useSelector((state) => state.faqDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = faqDelete

  const faqCreate = useSelector((state) => state.faqCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    faq: createdFaq,
  } = faqCreate

  const faqSort = useSelector((state) => state.faqSort)
  const {
    loading: loadingSort,
    error: errorSort,
    success: successSort,
    faqs: newSortedFaqs,
  } = faqSort

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    // dispatch({ type: FAQ_LIST_RESET })
    dispatch({ type: FAQ_CREATE_RESET })
    dispatch({ type: FAQ_DETAILS_RESET })
    // dispatch(listFaqs())

    if (!userInfo.isAdmin) {
      history('/')
    }

    if (successCreate) {
      history(`/admin/faq/${createdFaq._id}/edit`)
      dispatch(listFaqs())
    } else {
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdFaq,
    successSort,
  ])

  // useEffect(() => {
  //   dispatch(listFaqs())
  // }, [dispatch])

  useEffect(() => {
    if (!loading) {
      setSortedFaqs(faqs)
    }
  }, [loading])

  // useEffect(() => {
  //   console.log(sortedFaqs)
  // }, [sortedFaqs])

  const deleteHandler = (id) => {
    if (
      window.confirm(
        `WARNING! THIS ACTION CANNOT BE UNDONE! Are you sure you want to delete this FAQ?`
      )
    ) {
      dispatch(deleteFaq(id))
    }
  }

  const createFaqHandler = () => {
    dispatch(createFaq())
  }

  const faqSortHandler = () => {
    dispatch(sortFaqs(sortedFaqs))
    history('/admin/panel')
  }

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newSortedFaqs = swap(sortedFaqs, sourceIndex, targetIndex)
    setSortedFaqs(newSortedFaqs)
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Edit FAQS</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <Link to='/admin/panel' className='btn btn-light my-3'>
            Go Back
          </Link>
        </Col>

        <Col className='text-right'>
          <Button className='my-3' onClick={createFaqHandler}>
            <i className='fas fa-plus'></i> Create FAQ
          </Button>
        </Col>

        <Col className='saveSortedFaqsButton text-center my-auto'>
          <Button
            // variant='primary'
            className='my-3'
            onClick={() => faqSortHandler()}
          >
            Save New Faq Arrangement
          </Button>
        </Col>
      </Row>

      <Row>
        <Col
          xs={5}
          style={{
            borderRight: '1px solid black',
            padding: '10px',
          }}
        >
          Question
        </Col>
        <Col xs={6} style={{ padding: '10px' }}>
          Answer
        </Col>
        <Col></Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id='items'
            boxesPerRow={1}
            rowHeight={100}
            style={{ height: 100 * Math.ceil(sortedFaqs.length / 1) }}
          >
            {sortedFaqs.map((faq) => (
              <GridItem
                className='grid-item'
                key={faq._id}
                style={{
                  overflow: 'hidden',
                  border: '1px solid black',
                }}
              >
                <Row style={{ padding: '10px' }}>
                  <Col xs={5} style={{ borderRight: '1px solid black' }}>
                    {faq.question}
                  </Col>
                  <Col xs={6} style={{ borderRight: '1px solid black' }}>
                    {faq.answer}
                  </Col>

                  <Col>
                    <LinkContainer to={`/admin/faq/${faq._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>

                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(faq._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </>
  )
}

export default AdminFaqScreen
