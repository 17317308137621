import React from 'react'
import { Image } from 'react-bootstrap'

const Logo = ({ logo }) => {
  return (
    <Image
      src={logo && logo.image}
      fluid='true'
      className='zero big-logo align-items-center'
      alt='Taylor Moon Tattoos Logo'
      loading='lazy'
    />
  )
}

export default Logo
