import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Photo from '../components/Photo'
import FormContainer from '../components/FormContainer'
import {
  listPhotos,
  deletePhoto,
  createPhoto,
  updatePhoto,
  sortPhotos,
} from '../actions/photoActions'
import {
  PHOTO_CREATE_RESET,
  PHOTO_SORT_RESET,
  PHOTO_UPDATE_RESET,
  PHOTO_DELETE_RESET,
} from '../constants/photoConstants'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd'

const AdminPortfolioScreen = ({ photos }) => {
  const { id } = useParams()

  const [image, setImage] = useState('')

  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const [sortedPhotos, setSortedPhotos] = useState(photos)

  const photoUpdate = useSelector((state) => state.photoUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = photoUpdate

  const photoDelete = useSelector((state) => state.photoDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = photoDelete

  const photoCreate = useSelector((state) => state.photoCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    photo: createdPhoto,
  } = photoCreate

  const photoSort = useSelector((state) => state.photoSort)
  const {
    loading: loadingSort,
    error: errorSort,
    success: successSort,
    photos: newSortedPhotos,
  } = photoSort

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const history = useNavigate()

  useEffect(() => {
    dispatch({ type: PHOTO_CREATE_RESET })

    if (!userInfo.isAdmin) {
      history('/')
    }

    if (successCreate) {
      console.log(image)
      dispatch(listPhotos(image))
    }

    if (successDelete) {
      dispatch({ type: PHOTO_DELETE_RESET })
      dispatch(listPhotos(image))
    }

    if (successUpdate) {
      dispatch({ type: PHOTO_UPDATE_RESET })
      dispatch(listPhotos())
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    successUpdate,
    createdPhoto,
    successSort,
  ])

  const deleteHandler = (id) => {
    if (
      window.confirm(
        `WARNING! THIS ACTION CANNOT BE UNDONE! Are you sure you want to delete this photo?`
      )
    ) {
      dispatch(deletePhoto(id))
    }
  }

  const createPhotoHandler = () => {
    dispatch(createPhoto())
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]

    const formData = new FormData()

    formData.append('image', file)

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch {
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(createPhoto(image))
  }

  const photoSortHandler = () => {
    dispatch(sortPhotos(sortedPhotos))
    dispatch(listPhotos())
    history('/admin/panel')
  }

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newSortedPhotos = swap(sortedPhotos, sourceIndex, targetIndex)
    setSortedPhotos(newSortedPhotos)
  }

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Edit Portfolio</h1>
        </Col>
      </Row>

      <Row style={{ marginBottom: 25 }}>
        <Col>
          <Link to='/admin/panel' className='btn btn-light my-3'>
            Go Back
          </Link>
        </Col>

        <Col className='saveSortedPhotosButton text-center my-auto'>
          <Button
            variant='primary'
            className='btn-lg'
            onClick={() => photoSortHandler()}
          >
            Save New Portfolio Arrangement
          </Button>
        </Col>

        <Col className='text-right'>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='image'>
              <Form.Label className='margin-top-1point5rem'>
                Upload Image
              </Form.Label>

              <Form.Control
                type='file'
                label='Choose File'
                onChange={uploadFileHandler}
              ></Form.Control>

              {uploading && <Loader />}
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Add Photo
            </Button>
          </Form>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id='items'
          boxesPerRow={6}
          rowHeight={255}
          style={{ height: 255 * Math.ceil(sortedPhotos.length / 6) }}
        >
          {sortedPhotos.map((photo, index) => (
            <GridItem key={photo._id} className='grid-item'>
              <Photo photo={photo} />

              <Button
                variant='danger'
                className='btn-sm'
                onClick={() => deleteHandler(photo._id)}
              >
                <i className='fas fa-trash'></i>
              </Button>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </>
  )
}

export default AdminPortfolioScreen
