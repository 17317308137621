import React /*, { useEffect, useRef }*/ from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
// import { useDispatch, useSelector } from 'react-redux'
// import { listPhotos } from '../actions/photoActions'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'
import 'swiper/css/free-mode'
import 'swiper/css/zoom'
import FadeIn from 'react-fade-in'

const SwiperCarousel = ({ photos }) => {
  const middleIndex = Math.ceil(photos.length / 2)

  const firstCarouselPhotos = photos.slice().splice(0, middleIndex)
  const secondCarouselPhotos = photos.slice().splice(-middleIndex)

  const swiperParameters = {
    modules: [Autoplay],
    grabCursor: false,
    resistanceRatio: 0.85,
    spaceBetween: 5,
    followFinger: true,
    simulateTouch: true,
    slidesPerGroup: 1,
    allowTouchMove: true,
    direction: 'horizontal',
    slidesPerView: 'auto',
    allowSlideNext: true,
    shortSwipes: true,
    slidesPerGroupAuto: false,
    touchRatio: 1.5,
    threshold: 0,
    speed: 9000,
    resistance: true,
    longSwipes: true,
    allowSlidePrev: true,
    loop: true,
    // loopedSlides: 50,
    autoplay: {
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
      enabled: true,
      delay: 600,
    },
    slidesPerView: 'auto',
  }
  const swiperParametersTwo = {
    modules: [Autoplay],
    grabCursor: false,
    resistanceRatio: 0.85,
    spaceBetween: 5,
    followFinger: true,
    simulateTouch: true,
    slidesPerGroup: 1,
    allowTouchMove: true,
    direction: 'horizontal',
    slidesPerView: 'auto',
    allowSlideNext: true,
    shortSwipes: true,
    slidesPerGroupAuto: false,
    touchRatio: 1.5,
    threshold: 0,
    speed: 9000,
    resistance: true,
    longSwipes: true,
    allowSlidePrev: true,
    loop: true,
    autoplay: {
      reverseDirection: true,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
      enabled: true,
      delay: 600,
    },
    slidesPerView: 'auto',
  }

  return (
    <FadeIn>
      <Swiper id='swiper' {...swiperParameters}>
        {firstCarouselPhotos.map((photo) => (
          <SwiperSlide id='swiper-slide' key={photo._id}>
            <Image
              src={photo.image}
              alt={photo.name}
              fluid
              className='swiper-slide-image'
              id='swiper-slide-image'
              loading='lazy'
            />
          </SwiperSlide>
        ))}
        {firstCarouselPhotos.map((photo) => (
          <SwiperSlide id='swiper-slide' key={photo._id}>
            <Image
              src={photo.image}
              alt={photo.name}
              fluid
              className='swiper-slide-image'
              id='swiper-slide-image'
              loading='lazy'
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Navbar id='SwiperNavbar' className='justify-content-center'>
        <Container
          id='SwiperNavbarContainer'
          className='justify-content-center'
        >
          <Row id='SwiperNavbarRow' className='justify-content-center'>
            <Col className='SwiperNavbarCol'>
              <LinkContainer to='/contact'>
                <Nav.Link className='text-align-center SwiperNavLink swiperNavbarZoom'>
                  <div className='text-align-center SwiperNavLinkButton'>
                    Book an Appointment!
                  </div>
                </Nav.Link>
              </LinkContainer>
            </Col>
            <Col className='SwiperNavbarCol' md={{ order: 'first' }}>
              <LinkContainer to='/shop'>
                <Nav.Link className='text-align-center SwiperNavLink swiperNavbarZoom'>
                  <div className='text-align-center SwiperNavLinkButton'>
                    Shop Products
                  </div>
                </Nav.Link>
              </LinkContainer>
            </Col>
            <Col className='SwiperNavbarCol'>
              <LinkContainer to='/shop'>
                <Nav.Link className='text-align-center SwiperNavLink swiperNavbarZoom'>
                  <div className='text-align-center SwiperNavLinkButton'>
                    View Designs
                  </div>
                </Nav.Link>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Swiper id='swiperTwo' {...swiperParametersTwo}>
        {secondCarouselPhotos.map((photo) => (
          <SwiperSlide id='swiper-slide' key={photo._id}>
            <Image
              src={photo.image}
              alt={photo.name}
              fluid
              className='swiper-slide-image'
              id='swiper-slide-image'
              loading='lazy'
            />
          </SwiperSlide>
        ))}
        {secondCarouselPhotos.map((photo) => (
          <SwiperSlide id='swiper-slide' key={photo._id}>
            <Image
              src={photo.image}
              alt={photo.name}
              fluid
              className='swiper-slide-image'
              id='swiper-slide-image'
              loading='lazy'
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </FadeIn>
  )
}

export default SwiperCarousel
