import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
import { useParams } from 'react-router-dom'
import FadeIn from 'react-fade-in'

const ShopScreen = () => {
  return (
    <>
      <Meta />
      <FadeIn>
        <h1 className='text-align-center'>Shop</h1>

        <p className='text-align-center'>
          This page is currently under maintenance.
        </p>
      </FadeIn>
    </>
  )
}

export default ShopScreen
