export const TATTOO_LIST_REQUEST = 'TATTOO_LIST_REQUEST'
export const TATTOO_LIST_SUCCESS = 'TATTOO_LIST_SUCCESS'
export const TATTOO_LIST_FAIL = 'TATTOO_LIST_FAIL'

export const TATTOO_DETAILS_REQUEST = 'TATTOO_DETAILS_REQUEST'
export const TATTOO_DETAILS_SUCCESS = 'TATTOO_DETAILS_SUCCESS'
export const TATTOO_DETAILS_FAIL = 'TATTOO_DETAILS_FAIL'
export const TATTOO_DETAILS_RESET = 'TATTOO_DETAILS_RESET'

export const TATTOO_DELETE_REQUEST = 'TATTOO_DELETE_REQUEST'
export const TATTOO_DELETE_SUCCESS = 'TATTOO_DELETE_SUCCESS'
export const TATTOO_DELETE_FAIL = 'TATTOO_DELETE_FAIL'

export const TATTOO_UPDATE_REQUEST = 'TATTOO_UPDATE_REQUEST'
export const TATTOO_UPDATE_SUCCESS = 'TATTOO_UPDATE_SUCCESS'
export const TATTOO_UPDATE_FAIL = 'TATTOO_UPDATE_FAIL'
export const TATTOO_UPDATE_RESET = 'TATTOO_UPDATE_RESET'

export const TATTOO_CREATE_REQUEST = 'TATTOO_CREATE_REQUEST'
export const TATTOO_CREATE_SUCCESS = 'TATTOO_CREATE_SUCCESS'
export const TATTOO_CREATE_FAIL = 'TATTOO_CREATE_FAIL'
export const TATTOO_CREATE_RESET = 'TATTOO_CREATE_RESET'

export const TATTOO_SORT_REQUEST = 'TATTOO_SORT_REQUEST'
export const TATTOO_SORT_SUCCESS = 'TATTOO_SORT_SUCCESS'
export const TATTOO_SORT_FAIL = 'TATTOO_SORT_FAIL'
export const TATTOO_SORT_RESET = 'TATTOO_SORT_RESET'

export const TATTOO_DECREMENT_REQUEST = 'TATTOO_DECREMENT_REQUEST'
export const TATTOO_DECREMENT_SUCCESS = 'TATTOO_DECREMENT_SUCCESS'
export const TATTOO_DECREMENT_FAIL = 'TATTOO_DECREMENT_FAIL'
export const TATTOO_DECREMENT_RESET = 'TATTOO_DECREMENT_RESET'
