import axios from 'axios'
import {
  BOOKING_GET_REQUEST,
  BOOKING_GET_SUCCESS,
  BOOKING_GET_FAIL,
  BOOKING_GET_RESET,
  BOOKING_UPDATE_REQUEST,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_UPDATE_RESET,
} from '../constants/bookingConstants'

export const getBooking = () => async (dispatch) => {
  try {
    dispatch({ type: BOOKING_GET_REQUEST })

    const { data } = await axios.get('/api/booking')

    dispatch({
      type: BOOKING_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BOOKING_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateBooking = (booking) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOKING_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put('/api/booking', booking, config)

    dispatch({
      type: BOOKING_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BOOKING_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
