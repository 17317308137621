import axios from 'axios'
import {
  INFO_GET_REQUEST,
  INFO_GET_SUCCESS,
  INFO_GET_FAIL,
  INFO_GET_RESET,
  INFO_UPDATE_REQUEST,
  INFO_UPDATE_SUCCESS,
  INFO_UPDATE_FAIL,
  INFO_UPDATE_RESET,
} from '../constants/infoConstants'

export const getInfo = () => async (dispatch) => {
  try {
    dispatch({ type: INFO_GET_REQUEST })

    const { data } = await axios.get('/api/info')

    dispatch({
      type: INFO_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INFO_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateInfo = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INFO_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/info`, info, config)

    dispatch({
      type: INFO_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: INFO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
