import React, { useState } from 'react'
import { Row, Col, Button, Carousel, Image, Container } from 'react-bootstrap'
import Photo from '../components/Photo'
import Meta from '../components/Meta'
import { useParams } from 'react-router-dom'
import FadeIn from 'react-fade-in'
import Modal from 'react-bootstrap/Modal'
import PortfolioSwiper from '../components/PortfolioSwiper'

const PortfolioScreen = ({ photos }) => {
  const [show, setShow] = useState(false)

  const showModalHandler = () => {
    setShow(true)
  }

  return (
    <>
      <Meta />

      {/* <Modal
        show={show}
        fullscreen={true}
        onHide={() => setShow(false)}
        id='portfolioModal'
      >
        <PortfolioSwiper photos={photos} />
      </Modal> */}

      <FadeIn>
        <h1 className='text-align-center'>Portfolio</h1>
        <Row>
          {photos.map((photo, index) => (
            <Col key={photo._id} sm={6} md={4} lg={4} xl={3} id='photo-col'>
              {/* <Button
                // className='photoButton expandPortfolioPhoto'
                className='photoButton'
                // onClick={() => showModalHandler()}
              > */}
              <Photo
                photo={photo}
                key={index}
                // className='photoButton expandPortfolioPhoto'
                className='photoButton'
              />
              {/* </Button> */}
            </Col>
          ))}
        </Row>
      </FadeIn>
    </>
  )
}

export default PortfolioScreen
