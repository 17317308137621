import axios from 'axios'

import {
  TATTOO_LIST_REQUEST,
  TATTOO_LIST_SUCCESS,
  TATTOO_LIST_FAIL,
  TATTOO_DETAILS_REQUEST,
  TATTOO_DETAILS_SUCCESS,
  TATTOO_DETAILS_FAIL,
  TATTOO_DETAILS_RESET,
  TATTOO_DELETE_FAIL,
  TATTOO_DELETE_SUCCESS,
  TATTOO_DELETE_REQUEST,
  TATTOO_CREATE_FAIL,
  TATTOO_CREATE_SUCCESS,
  TATTOO_CREATE_REQUEST,
  TATTOO_UPDATE_FAIL,
  TATTOO_UPDATE_SUCCESS,
  TATTOO_UPDATE_REQUEST,
  TATTOO_SORT_REQUEST,
  TATTOO_SORT_SUCCESS,
  TATTOO_SORT_FAIL,
  TATTOO_DECREMENT_REQUEST,
  TATTOO_DECREMENT_SUCCESS,
  TATTOO_DECREMENT_FAIL,
  TATTOO_DECREMENT_RESET,
} from '../constants/tattooConstants'

export const sortTattoos = (newTattoos) => async (dispatch, getState) => {
  try {
    dispatch({ type: TATTOO_SORT_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put('/api/tattoos', newTattoos, config)

    dispatch({
      type: TATTOO_SORT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_SORT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTattoos = () => async (dispatch) => {
  try {
    dispatch({ type: TATTOO_LIST_REQUEST })

    const { data } = await axios.get(`/api/tattoos`)

    dispatch({
      type: TATTOO_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTattooDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TATTOO_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/tattoos/${id}`)

    dispatch({
      type: TATTOO_DETAILS_SUCCESS,

      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_DETAILS_FAIL,

      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteTattoo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TATTOO_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/tattoos/${id}`, config)

    dispatch({
      type: TATTOO_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_DELETE_FAIL,

      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createTattoo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TATTOO_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/tattoos`, {}, config)

    dispatch({
      type: TATTOO_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_CREATE_FAIL,

      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTattoo = (tattoo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TATTOO_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/tattoos/${tattoo._id}`,
      tattoo,
      config
    )

    dispatch({
      type: TATTOO_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_UPDATE_FAIL,

      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const decrementTattoo = (tattoo) => async (dispatch) => {
  try {
    dispatch({
      type: TATTOO_DECREMENT_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(
      `/api/tattoos/${tattoo._id}`,
      tattoo,
      config
    )

    dispatch({
      type: TATTOO_DECREMENT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TATTOO_DECREMENT_FAIL,

      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
