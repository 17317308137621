import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listFaqDetails, updateFaq } from '../actions/faqActions'
import { FAQ_UPDATE_RESET } from '../constants/faqConstants'

const FaqEditScreen = () => {
  const { id } = useParams()
  const faqId = id

  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')

  const dispatch = useDispatch()

  const faqDetails = useSelector((state) => state.faqDetails)
  const { loading, error, faq } = faqDetails

  const faqUpdate = useSelector((state) => state.faqUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = faqUpdate

  const history = useNavigate()

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: FAQ_UPDATE_RESET })
      history('/admin/panel/faqs')
    } else {
      if (!faq.question || faq._id !== faqId) {
        dispatch(listFaqDetails(faqId))
      } else {
        setQuestion(faq.question)
        setAnswer(faq.answer)
      }
    }
  }, [dispatch, history, faqId, faq, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateFaq({
        _id: faqId,
        question,
        answer,
      })
    )
  }

  return (
    <>
      <Link to='/admin/panel/faqs' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit FAQ</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='question'>
              <Form.Label>FAQ Question</Form.Label>

              <Form.Control
                as='textarea'
                rows={5}
                type='text'
                placeholder='Enter FAQ question'
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='answer'>
              <Form.Label>FAQ Answer</Form.Label>

              <Form.Control
                as='textarea'
                rows={5}
                type='text'
                placeholder='Enter FAQ answer'
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default FaqEditScreen
