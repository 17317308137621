import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productCreateReviewReducer,
  productUpdateReducer,
  productTopRatedReducer,
  productSortReducer,
  productDecrementReducer,
} from './reducers/productReducers'

import {
  photoListReducer,
  photoDeleteReducer,
  photoCreateReducer,
  photoUpdateReducer,
  photoSortReducer,
} from './reducers/photoReducers'

import { logoGetReducer, logoUpdateReducer } from './reducers/logoReducers'

import { cartReducer } from './reducers/cartReducers'

import { aboutGetReducer, aboutUpdateReducer } from './reducers/aboutReducers'

import { infoGetReducer, infoUpdateReducer } from './reducers/infoReducers'

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers'

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  orderListReducer,
  orderDeliverReducer,
  orderDeleteReducer,
} from './reducers/orderReducers'

import {
  tattooListReducer,
  tattooDetailsReducer,
  tattooDeleteReducer,
  tattooCreateReducer,
  tattooUpdateReducer,
  tattooSortReducer,
  tattooDecrementReducer,
} from './reducers/tattooReducers'

import {
  faqListReducer,
  faqCreateReducer,
  faqDeleteReducer,
  faqUpdateReducer,
  faqDetailsReducer,
  faqSortReducer,
} from './reducers/faqReducers'

import { emailReceiptReducer } from './reducers/emailReducers'

import {
  bookingGetReducer,
  bookingUpdateReducer,
} from './reducers/bookingReducers'

const reducer = combineReducers({
  bookingGet: bookingGetReducer,
  bookingUpdate: bookingUpdateReducer,
  emailReceipt: emailReceiptReducer,
  photoSort: photoSortReducer,
  productSort: productSortReducer,
  faqSort: faqSortReducer,
  tattooSort: tattooSortReducer,
  infoGet: infoGetReducer,
  infoUpdate: infoUpdateReducer,
  tattooList: tattooListReducer,
  tattooDetails: tattooDetailsReducer,
  tattooDelete: tattooDeleteReducer,
  tattooCreate: tattooCreateReducer,
  tattooUpdate: tattooUpdateReducer,
  tattooDecrement: tattooDecrementReducer,
  orderDelete: orderDeleteReducer,
  faqList: faqListReducer,
  faqDetails: faqDetailsReducer,
  faqCreate: faqCreateReducer,
  faqUpdate: faqUpdateReducer,
  faqDelete: faqDeleteReducer,
  aboutGet: aboutGetReducer,
  aboutUpdate: aboutUpdateReducer,
  logoGet: logoGetReducer,
  logoUpdate: logoUpdateReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productCreateReview: productCreateReviewReducer,
  productUpdate: productUpdateReducer,
  productTopRated: productTopRatedReducer,
  productDecrement: productDecrementReducer,
  // Adding photoListReducer
  photoList: photoListReducer,
  photoDelete: photoDeleteReducer,
  photoCreate: photoCreateReducer,
  photoUpdate: photoUpdateReducer,
  // Will attach payload data from photoListReducer to photoList part of the state
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,
})

const cartItemsFromStorage = sessionStorage.getItem('cartItems')
  ? JSON.parse(sessionStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null

const cartCountryFromStorage = sessionStorage.getItem('cartCountry')
  ? JSON.parse(sessionStorage.getItem('cartCountry'))
  : '- Select -'

const cartStateFromStorage = sessionStorage.getItem('cartState')
  ? JSON.parse(sessionStorage.getItem('cartState'))
  : '- Select -'

const subscribedFromStorage = sessionStorage.getItem('subscribed')
  ? JSON.parse(sessionStorage.getItem('subscribed'))
  : false

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    cartCountry: cartCountryFromStorage,
    cartState: cartStateFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
  // subscribed: { subscribedYet: subscribedFromStorage },
}

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export default store
