import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getAbout, updateAbout } from '../actions/aboutActions'
import { ABOUT_UPDATE_RESET } from '../constants/aboutConstants'
import { productCreateReducer } from '../reducers/productReducers'
import { Col, Card } from 'react-bootstrap'

const AdminAboutScreen = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const aboutGet = useSelector((state) => state.aboutGet)
  const { loading, error, about } = aboutGet

  const [image, setImage] = useState('')
  const [bio, setBio] = useState('')
  const [uploading, setUploading] = useState(false)

  const aboutUpdate = useSelector((state) => state.aboutUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = aboutUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: ABOUT_UPDATE_RESET })
      dispatch(getAbout())
      history('/admin/panel')
    } else {
      if (!about.image || !about.bio) {
        dispatch(getAbout())
      } else {
        setImage(about.image)
        setBio(about.bio)
      }
    }
  }, [dispatch, about.image, about.bio, successUpdate])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]

    const formData = new FormData()

    formData.append('image', file)

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      updateAbout({
        image,
        bio,
      })
    )
  }

  return (
    <>
      <Link to='/admin/panel' className='btn btn-light my-3'>
        Go Back
      </Link>

      <FormContainer>
        <h1>Edit About Page</h1>

        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Col sm={12} md={12}>
              <Card>
                <Card.Img src={image} />
              </Card>
            </Col>
            <Form.Group controlId='image'>
              <Form.Label>About Page Image</Form.Label>

              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>

              <Form.Control
                type='file'
                label='Choose File'
                onChange={uploadFileHandler}
              ></Form.Control>

              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='bio'>
              <Form.Label className='margin-top-1point5rem'>
                About Page Bio
              </Form.Label>

              <Form.Control
                as='textarea'
                rows={5}
                type='text'
                placeholder='Enter about page bio...'
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button
              type='submit'
              variant='primary'
              className='margin-top-1point5rem'
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default AdminAboutScreen
